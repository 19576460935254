<script>
  import BaseSelect from "./components/elements/selects/BaseSelect.vue";
  import SliderStock from "./components/elements/sliders/SliderStock.vue";
  import VSelect from "./components/common/VSelect.vue";
  import BRANDS from "./api/brands.js";
  import MODELS from "./api/models.js";
  import COMPLECTATIONS from "./api/complectations.js";
  
  export default {
    components: {
      BaseSelect,
      SliderStock,
      VSelect,
    },
    BRANDS,
    MODELS,
    COMPLECTATIONS,
    data() {
      return {
        brand: null,
        model: null,
        complectation: null,
        brands: this.$options.BRANDS,
        models: null,
        complectations: null,
      }
    },
    watch: {
      brand() {
        if (this.brand) {
          this.$options.MODELS.forEach((element) => {
            if (element[this.brand.name]) {
              this.models = element[this.brand.name].models;
            }
          });
          this.model = null;
        }
      },
      model() {
        if (this.model) {
          this.$options.COMPLECTATIONS.forEach((element) => {
            if (element[this.model.name]) {
              this.complectations = element[this.model.name];
            }
          });
        }
        this.complectation = null;
      },
    },
  }
</script>