<script>
import SvgCheck from '/source/js/vue/components/icons/Check.vue';

export default {
  name: 'SelectOption',
  components: {
    SvgCheck,
  },
  props: {
    option: {
      type: [String, Number],
      required: true,
    },
    isOptionSelected: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  methods: {
    onOptionClick() {
      this.$emit('on-option-click', this.option);
    },
  },
};
</script>

<template>
  <div
    class="flex items-center hover:bg-grey-light cursor-pointer space-x-2 "
    :class="[theme ? 'group' : '']"
    @click="onOptionClick"
  >
    <span :class="['relative flex-shrink-0 ml-4 w-5 h-5 rounded border ', theme ? 'border-white group-hover:border-black' : 'border-gray-500']">
      <svg-check
        class="abs-center w-4 text-green fill-current"
        :class="{
          hidden: isOptionSelected,
        }"
      />
    </span>
    <span :class="['w-full py-4 pl-1 pr-4 ', theme ? 'text-white hover:text-black' : 'text-gray-600']" v-html="option"></span>
  </div>
</template>

<style scoped>
.abs-center {
  @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
}
</style>
