export default [
  {
    "LX": [
      { id: 1, name: "Prestige 1.5T 147 CVT" }
    ]
  },
  {
    "TXL": [
      { id: 1, name: "Flagship"},
      { id: 2, name: "Flagship SE"},
      { id: 3, name: "Luxury"},
    ]
  },
  {
    "VX": [
      { id: 1, name: "President"},
      { id: 2, name: "President LE"},
      { id: 3, name: "Luxury"},
    ]
  }
]
