<template>
  <div class="container" v-if="conditions[1] == 'about'">
    <div class="my-7">
      <h1
        v-if="
          conditions[3] == 0 || conditions.length == 1
        "
        class="xl:b-12 mb-7 text-center text-lg font-bold uppercase text-black xl:text-[28px]"
      >
        ПРАВОВАЯ ИНФОРМАЦИЯ
      </h1>
      <h1
        v-else-if="conditions[3] === 'requisites'"
        class="mb-7 text-center text-lg font-bold uppercase text-black xl:mb-12 xl:text-[28px]"
      >
        Реквизиты ООО «АТЦ Кунцево»
      </h1>
      <h1
        v-else-if="conditions[3] === 'terms-of-used'"
        class="mb-7 text-center text-lg font-bold uppercase text-black xl:mb-12 xl:text-[28px]"
      >
        Пользовательское соглашение
      </h1>
      <h1
        v-else-if="conditions[3] === 'policy-personal-data'"
        class="mb-7 text-center text-lg font-bold uppercase text-black xl:mb-12 xl:text-[28px]"
      >
        Политика конфиденциальности
      </h1>
      <div
          class="flex flex-col gap-y-7 xl:gap-y-12"
          v-if="
            conditions[3] == 0 ||
            conditions.length == 1
          "
        >
          <a class="text-base underline underline-offset-4 xl:text-lg"
            href="/about/terms-conditions/requisites/"
            >Реквизиты ООО «АТЦ Кунцево»</a>
          <a class="text-base underline underline-offset-4 xl:text-lg"
            href="/about/terms-conditions/terms-of-used/"
            >Пользовательское соглашение</a>
          <a class="text-base underline underline-offset-4 xl:text-lg"
            href="/about/terms-conditions/policy-personal-data/"
            >Политика конфиденциальности</a>
        </div>
        <div
          class="space-y-2 xl:space-y-4"
          v-if="conditions[3] == 'requisites'"
        >
          <p>Юридическая информация ООО «АТЦ Кунцево»</p>
          <p>
            Юридический адрес - 143025, Московская область, город Одинцово, с.
            Немчиновка, ул. Московская, д. 61
          </p>
          <p>ИНН 5032271852</p>
          <p>КПП 503201001</p>
          <p>
            Основной государственный регистрационный номер(ОГРН) 1135032010310
          </p>
          <p>E-mail <a href="mailto:info@kuntsevo.com">info@kuntsevo.com</a></p>
        </div>
        <div
          class="space-y-2 xl:space-y-4"
          v-if="conditions[3] == 'terms-of-used'"
        >
          <p>
            Оставляя свои персональные и контактные данные посредством
            заполнения информационных полей онлайн-заявок, вы:
          </p>
          <p>
            признаете и подтверждаете, что внимательно прочитали нижеизложенные
            условия обработки ваших данных, указываемых вами в полях on-line
            заявки;
          </p>
          <p>
            выражаете согласие с такими условиями без ограничений и оговорок.
            Под персональными данными подразумевается любая информация,
            относящаяся к субъекту персональных данных, в том числе фамилия, имя
            и отчество, дата рождения, адрес, контактные реквизиты (телефон,
            адрес электронной почты), семейное, имущественное положение и иные
            данные, относимые Федеральным законом от 27 июля 2006 года № 152-ФЗ
            «О персональных данных» (далее – «Закон») к категории персональных
            данных.
          </p>
          <p>
            Направление информации через сайт Группы Компаний ТЦ Кунцево
            означает ваше согласие на обработку предоставляемых персональных
            данных в объеме, в котором они были представлены организации
            входящую в Группу Компаний ТЦ Кунцево, указанной на сайте, в порядке
            и на условиях, определенных Законом любым способом, предусмотренным
            Группой Компаний ТЦ Кунцево и(или) установленных Законом.
          </p>
          <p>
            Целью обработки персональных данных является продажа Товаров и
            оказание услуг Группой Компаний ТЦ Кунцево, а также информирование
            об оказываемых Группой Компаний ТЦ Кунцево услугах и реализуемых
            Товарах. В случае отзыва согласия на обработку своих персональных
            данных организация, входящая в Группу Компаний ТЦ Кунцево, указанная
            на сайте, прекратит их обработку и уничтожит данные в срок, не
            превышающий трех рабочих дней с даты поступления такого отзыва.
            Письменный отзыв согласия на обработку ваших персональных данных вы
            можете предоставить в офис организации входящей в Группу Компаний ТЦ
            Кунцево.
          </p>
        </div>
        <div
          class="space-y-2 xl:space-y-4"
          v-if="conditions[3] == 'policy-personal-data'"
        >
          <p>
            Настоящим я (субъект персональных данных) в соответствии с
            Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»
            свободно, по своей воле и в своем интересе даю согласие Компании АО
            «ЧЕРИ АВТОМОБИЛИ РУС» (местонахождение и почтовый адрес: 127495, г.
            Москва, Дмитровское шоссе, 163А корп.2, 11 этаж) на обработку на
            указанных далее условиях следующих данных, в том числе, моих
            персональных данных:
          </p>
          <p>- фамилия, имя, отчество;</p>
          <p>- номер контактного телефона,</p>
          <p>- адрес электронной почты,</p>
          <p>- город субъекта персональных данных,</p>
          <p>- дилер, относительно которого осуществляется обращение;</p>
          <p>- модель автомобиля, по которой осуществляется обращение.</p>
          <p>Цели обработки:</p>
          <p>- обработка обращения субъекта на горячую линию;</p>
          <p>
            - контроль качества предоставленного Компанией или соответствующим
            дилером ответа субъекту персональных данных;
          </p>
          <p>
            - периодическое уведомление субъекта персональных данных о товарах,
            работах, услугах марки Chery.
          </p>
          <p>Действия, совершаемые при обработки персональных данных:</p>
          <p>
            сбор, запись, систематизация, накопление, хранение, уточнение
            (обновление, изменение), извлечение,
          </p>
          <p>
            использование, передача, обезличивание, блокирование, удаление,
            уничтожение персональных данных.
          </p>
          <p>Способы обработки: автоматизированный и неавтоматизированный.</p>
          <p>
            Третьи лица, которым передаются персональные данные: соответствующей
            дилер Chery (относительно которого осуществляется обращение).
          </p>
          <p>
            Сроки обработки: в течение 5 лет, при этом обработка персональных
            данных может быть прекращена по запросу субъекта персональных
            данных, путем направления соответствующего письменного заявления по
            адресу Компании, указанному выше в настоящем согласии. В случае
            отзыва согласия на обработку персональных данных Компания вправе
            продолжить их обработку без согласия субъекта персональных данных
            при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6,
            части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О
            персональных данных» от 27.07.2006 г
          </p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms-item',
  data() {
    return {};
  },
  props: {
    pathRoutes: {
      type: String,
      required: true,
    },
  },

  components: {},

  created() {},

  mounted() {},

  beforeDestroy() {},

  methods: {},

  computed: {
    conditions() {
        return JSON.parse(this.pathRoutes);
    }
  },

  watch: {},

  directives: {},

  filters: {},
};
</script>

<style scoped lang="scss"></style>
