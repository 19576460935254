
<script>
import { Navigation, Pagination, Scrollbar, A11y, Grid } from 'swiper';
import {
  Swiper,
  SwiperSlide
} from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/grid';
import STOCK from '/source/js/vue/api/stock.js'

export default {
  props: ['brand', 'model', 'complectation'],
  components: {
    Swiper,
    SwiperSlide,
  },
  STOCK,

  data() {
    return {
      currentStock: [],
    }
  },
  setup() {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Grid]
    };
  },
  watch: {
    brand() {
      if (this.brand) {
        this.currentStock = this.$options.STOCK.filter(e => e.brand === this.brand.name);
      } else {
        this.currentStock = this.$options.STOCK;
      }
    },
    model() {
      if (this.model) {
        this.currentStock = this.$options.STOCK.filter(e => e.model === this.model.name);
      } else {
        this.currentStock = this.$options.STOCK.filter(e => e.brand === this.brand.name);
      }

    },
    complectation() {
      if (this.complectation) {
        this.currentStock = this.$options.STOCK.filter(e => e.complectation === this.complectation.name);
      } else {
         this.currentStock = this.$options.STOCK.filter(e => e.model === this.model.name);
      }
    },
  },
  created() {
    this.currentStock = this.$options.STOCK;
  },
  methods: {
    getImageUrl(brand, model, image) {
      if (brand && model) {
        return `/source/js/vue/assets/images/models/${brand.toUpperCase()}/${model.toUpperCase()}/89X150/${image}.jpg`;
      } else {
        return ''
      }
    },
  }
};
</script>

<template>
  <swiper :slides-per-view="1" :breakpoints="{
    '768': {
      slidesPerView: 2,
      spaceBetween: 20
    },
    '1440': {
      slidesPerView: 4,
      spaceBetween: 30,
      navigation : {
          nextEl: '.swiperstock-button-next',
          prevEl: '.swiperstock-button-prev',
      },
      grid: {
        fill: 'row',
        rows: 2,
      }
    },
    '1920': {
      slidesPerView: 4,
      spaceBetween: 40,
      navigation : {
          nextEl: '.swiperstock-button-next',
          prevEl: '.swiperstock-button-prev',
      },
      grid: {
        fill: 'row',
        rows: 2,
      }
    }
  }" :space-between="-80" @swiper="onSwiper" @slideChange="onSlideChange" :modules="modules"  >
    <swiper-slide v-for="(car,index) in currentStock" :key="index">
      <div class=" mt-4 rounded-md w-3/4 xl:w-full bg-white space-y-4 py-2 px-4">
        <div class="w-3/4 m-auto relative">
          <img :src="getImageUrl(car.brand, car.model, car.image)" alt="" class=" w-full h-full">
          <div class="absolute bottom-0 translate-y-1/2 -translate-x-1/4 right-0 w-1/4 bg-gradient-to-b from-[#FCFFFF] to-[#E8D5C6] rounded-md py-1 px-1 cursor-pointer text-center" js-popup="">
            <img src="/static/img/hot-or-burn-interface-symbol.png" alt="" class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 !h-6 !w-6 ">
            <span class=" uppercase font-bold text-xs z-20 ">sale</span>
          </div>
        </div>
        <div class="space-y-2 min-h-[74px]">
          <p class="font-bold uppercase text-md">{{ car.brand }} {{ car.model }}</p>
          <p class="text-sm">{{ car.complectation }}</p>
          <span class="text-sm text-red-500 relative before:bg-beigeRed before:content-[''] before:block before:h-1 before:left-0 before:absolute before:top-1/2 before:-rotate-[3deg] before:w-full before:opacity-50">{{ new Intl.NumberFormat().format(car.price) }} ₽</span>
          <p>ЦЕНЫ СНИЖЕНЫ! Ограниченная партия</p>
          <!-- <p class="opacity-50">Двигатель 1,6 л., 82 л.с., 4x4</p> -->
        </div>
          <div>

          </div>
          <div
            class="text-green-500 flex gap-2 items-center font-bold  before:block  before:rounded-full before:content-[''] before:w-4 before:h-4 before:bg-green-500">
            В наличии
          </div>
        <ItemButton data-title="Получить консультацию">
          <template #text>
            Получить консультацию
          </template>
        </ItemButton>
      </div>
    </swiper-slide>
  </swiper>
</template>
